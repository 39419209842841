import React from 'react';
import Layout from '../components/layout';
import { Link } from '@reach/router';
import { graphql } from 'gatsby';
import Img from "gatsby-image"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const productsPage = ({ data }) => (
    <Layout>
        <Row>
            <Col>
                <h1>Our Products</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <h2>Decorative Paints</h2>
            </Col>
        </Row>
        <Row className="productsPageItems">
            <Col><Img fluid={data.decorativePaints.childImageSharp.fluid}></Img></Col>
            <Col xs={12} sm={8}>
                <p>Threesons Paints offers a wide range of decorative paints for you. Our range of decorative materials includes Interior emulsions, Acrylic distemper, Exterior emulsions, Texture paints, Cement paints, Enamels and Wood grain finishes.</p>
                <Link to="/products/decorativePaintsPage/">View Products</Link>

            </Col>

        </Row>
        <hr />

        <Row>
            <Col>
                <h2>Industrial Paints</h2>
            </Col>
        </Row>

        <Row className="productsPageItems"> 
            <Col> <Img fluid={data.industrialPaints.childImageSharp.fluid}></Img>
            </Col>

            <Col xs={12} sm={8}>
                <p>We are a prominent manufacturer and Supplier of Synthetic Enamel, Stoving enamel, Quick Dry, N.C Paint, Epoxy paints, P.U Paints, Primer and Thinner etc. The products are accessible in different colors, shades, and specifications as per the requirement of the customers. They are resistant to high temperature as well as tough weather conditions. Owing to our industrial expertise and manufacturing facilities, we have been able to cater an assorted range of industrial paints and thinners.</p>

                <Link to="/products/industrialPaintsPage/">View Products</Link>
            </Col>
        </Row>
        <hr />


        <Row>
            <Col>
                <h2>Construction Care Chemicals </h2>
            </Col>
        </Row>

        <Row className="productsPageItems">
            <Col><Img fluid={data.constructionCareChemicals.childImageSharp.fluid}></Img></Col>
            <Col xs={12} sm={8}>
                <p>Threesons Paints offers a wide range of construction care chemicals for you. Our range of construction care chemicals includes isocoat, isocrete, isophobe, tile grout and admixutres.</p>

                <Link to="/products/constructionCarePage/">View Products</Link>
            </Col>
        </Row>

        <hr />
        <Row>
            <Col><h2>Epoxy Formulations</h2></Col>
        </Row>
        <Row className="productsPageItems">
            <Col><Img fluid={data.epoxyFormulations.childImageSharp.fluid}></Img>
            </Col>
            <Col xs={12} sm={8}>
                <p>We at Threesons have a highly well equipped, innovative and well experienced Epoxy division which is involved in the manufacturing & marketing of specialty resins & chemicals for the treatment of Granite, Marble and other stones. With a dedicated R&D team, our Epoxy division has been successful right from the beginning to the needs of various granite and marble industries.</p>

                <Link to="/products/epoxyFormulationsPage/">View Products</Link>
            </Col>
        </Row>


    </Layout>
)

export default productsPage;
export const query = graphql`
query {
    

    decorativePaints:file(relativePath:{eq:"decorativePaints.jpg"}){
        childImageSharp {
         fluid(maxWidth: 300) {
           ...GatsbyImageSharpFluid
         }
       }
     }

     industrialPaints:file(relativePath:{eq:"industrialPaints.jpg"}){
        childImageSharp {
         fluid(maxWidth: 300) {
           ...GatsbyImageSharpFluid
         }
       }
     }

     constructionCareChemicals:file(relativePath:{eq:"constructionCareChemicals.jpg"}){
        childImageSharp {
         fluid(maxWidth: 300) {
           ...GatsbyImageSharpFluid
         }
       }
     }

     epoxyFormulations:file(relativePath:{eq:"epoxyFormulations.jpg"}){
        childImageSharp {
         fluid(maxWidth: 300) {
           ...GatsbyImageSharpFluid
         }
       }
     }
  }
`